$(document).on("form-refresh", function (event, frm) {
    frappe.ui.form.on(frm.doctype, {
        refresh: function (frm) {
            // console.log(frappe.session.user)
            let doctype_name = [];
            
            frappe.call({
                method:"om_logistics.migrate.get_doctype_list",
              
                callback: function (r) {
                    r.message.forEach((dimension) => {
                            doctype_name.push(dimension);
                    });
                    if (doctype_name.includes(frm.doctype)) {
                        $(".remove-btn.cursor-pointer").hide();
                        $("a.btn.btn-xs.btn-default").hide();
                    }
                }
            });
            
            frappe.db.get_value("User", frappe.session.user, "allow_for_all_doctype", (r) => {
               if(r.allow_for_all_doctype == 1){
                    $(".remove-btn.cursor-pointer").hide();
                    $("a.btn.btn-xs.btn-default").hide();
               }
            });
           
        }
    });
});