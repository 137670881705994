frappe.provide("om_logistics.training_event")

om_logistics.training_event.get_out_office_trainee = function(frm){
    if (frm.doc.training_event){
        frm.call({
            doc:frm.doc,
            method:"get_other_employee",
            freeze:true,
            freeze_message:"Getting Out Office Trainees...",
            callback:function(){ 
                if (frm.is_new()){
                    frm.save() 
                }
            }
        })
    }else{
        frappe.msgprint(__("Please set Training Event first."))
    }
}
